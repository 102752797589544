import {useParams} from 'react-router';
import {useApi} from '../api';
import {FC, Fragment, ReactNode, useState} from 'react';
import {map} from 'lodash';

import DateCard from './DateCard';
import './Events.scss';

const Events = () => {
  const {name} = useParams();
  const [events, setEvents] = useState([]);
  useApi(`cal/show/${name}`, setEvents);

  return (
    <ul className='calendar-events'>
      {
        map(events, (event, index) => <li key={index}><Event {...event} /></li>)
      }
    </ul>
  );
};

export default Events;

const timeFormat = new Intl.DateTimeFormat('en-US', {timeStyle: 'short', hour12: false});
const dateFormat = new Intl.DateTimeFormat('en-US', {day: 'numeric', month: 'long'});

const getTime = (value: string, date?: boolean): string => {
  return (date ? dateFormat : timeFormat).format(Date.parse(value));
};

type extractTagsType = (text: string) => string | (string | ReactNode)[];

const tagExpr = /\[[^\]]*\]/;
const extractTags: extractTagsType = (text) => {
  if (tagExpr.test(text)) {
    return map(text.split('['), (chunk, index) => {
      const [tag, tail] = chunk.split(']', 2);
      return tail ?
        <Fragment key={index}><span>{tag}</span>{tail}</Fragment> :
        chunk;
    });
  }
  return text;
}

type EventPropsType = {
  title: string;
  start: string;
  end: string;
  description?: string;
  url?: string;
  location?: string;
};

const Event: FC<EventPropsType> = ({title, start, end, description, url, location}) => {
  const startDate = new Date(Date.parse(start));
  return (
    <article className='event'>
      <header>
        <div>
          <DateCard date={startDate} />
        </div>
        <div>
          <h2>{extractTags(title)}</h2>
          <i>
            <span>
              <time dateTime={start}>{getTime(start, true)}</time>
            </span>
            <span>
              <time dateTime={start}>{getTime(start)}</time>
              {
                end &&
                  <> - <time dateTime={end}>{getTime(end)}</time></>
              }
            </span>
            <span>{location}</span>
            <span></span>
          </i>
        </div>
      </header>
      <summary>{description}</summary>
      <footer>
        {
          url &&
            <a href={url}>{url}</a>
        }
      </footer>
    </article>
  );
}