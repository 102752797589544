import {FC} from 'react';

import './DateCard.scss';

const dateFormat = new Intl.DateTimeFormat('en-US', {month: 'short'});

const DateCard: FC<{date: Date}> = ({date}) => {
  const dayOfWeek = date.getDay();
  const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

  return (
    <time dateTime={date.toString()} className={`date-card${isWeekend ? ' weekend': ''}`}>
      <div className='month'>{dateFormat.format(date)}</div>
      <div>{date.getDate()}</div>
    </time>
  );
};

export default DateCard;
